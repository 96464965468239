'use strict';

angular.module('webPartnerPortalApp')
.controller('LoaderCtrl', [function(){

}])
.service('loader', ['$modal', function ($modal) {	
// AngularJS will instantiate a singleton by calling "new" on this function
	var loaderObj = {	
		show: function(){
			var modalInstance = $modal.open({
	        templateUrl: 'loader.html',
	        controller: 'LoaderCtrl',
	        controllerAs: 'vm',
	        windowClass: 'spinnerContent',
	        resolve: {
	          partner: function(){
	            
	          },
	          newPartner: function () {
	            return false;
	          }
	        }
	      });
			this.$modalInstance = modalInstance;
		},
		close: function(){
	        try{
	            this.$modalInstance.dismiss('cancel');
	        }catch(e){

	        }
	    }
	};
	return loaderObj;
}]);
